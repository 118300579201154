/* Large screens */
@media screen and (min-width: 1376px) {
  .App {
    max-width: 1000px;
    /* width: 100%; */
    /* height: 100vh; */
    margin: 0 auto;
    /* background-color: #fff; */
  }
}
