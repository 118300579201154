.phone-and-opening-wrapper {
  /* width: 100%; */

  /* align-self: flex-start; */

  margin: 0 1rem;

  display: grid;
  place-items: center;
}

.phone-and-opening {
  /* width: calc(100% - 2rem); */
  width: fit-content;

  margin: 0rem auto;

  padding: 0.25rem 2rem 0rem;

  /* display: grid;
  place-items: center; */

  /* background-image: linear-gradient(
        140deg,
        #221302 0%,
        #5a3508 50%,
        #c57000 75%
        ); */
  color: var(--main-text-color);
  background-color: var(--main-bg-color-darker-grey);

  border-radius: 10px;

  /*  */
  /* outline: 1px solid green; */
}

.phone {
}
.phone-box,
.opening-time-box {
  /* flex: 0.5; */
  width: fit-content;
  display: grid;
  place-items: center;
}
.phone-number {
  /* text-align: left; */
  flex: 1;
}
.phone {
  margin: 0.5rem 0;
  width: 220px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  /* outline: 1px solid #a59174; */
}
.opening-time {
  /* margin: 0.5rem 0; */
  width: 220px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.1rem;
  /* outline: 1px solid #a59174; */
}
.phone-box > svg {
  width: 30px;
  height: 40px;
}
.phone-box > svg > g {
  fill: #b7612c;
}

.opening-time-box > svg {
  width: 30px;
  height: 40px;
  fill: #b7612c;
}

.phone > svg {
  width: 35px;
  height: 35px;
  flex: 0 0 35px;
  margin-right: 1rem;
}
.opening-time > svg {
  width: 45px;
  height: 45px;
  flex: 0 0 45px;
  margin-right: 1rem;
}

.bottom-space {
  margin-bottom: 0.5rem;
}
