.menu-button {
  width: calc(50% - 0.5rem);
  max-width: 200px;
  /* height: auto; */

  padding: 1rem 0;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  /* row-gap: 0.4rem; */

  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;

  background-color: var(--main-bg-color-darker-grey);
}
.darker-bg {
  flex: 1;
  font-size: 1.4rem;
  font-weight: 500;
  letter-spacing: 1px;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  color: var(--main-text-color);
}
.darker-bg span {
  font-size: 1rem;
}

.flag-box {
  /* flex: 0.5; */
  display: grid;
  place-items: center;
}

.flag {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid var(--main-text-color);
}
