.header {
  /* margin-top: 2rem; */
  /* margin-bottom: 4rem; */

  /* width: 80vw; */

  /*   display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center; */

  justify-content: center;

  /* Testing border */
  /* border: 1px solid blue; */
}

.header-logo {
  /* !! Might need to change.. */
  width: 90%;
  /* width: 60vw; */
  margin: 0 auto;
}
