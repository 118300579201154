.language-picker {
  width: calc(100% - 2rem);

  margin: 1rem 1rem 0rem;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: stretch;
  gap: 1rem;
}
