html,
body {
  width: 100%;
  /* height: 100vh; */

  box-sizing: border-box;

  font-size: 16px;

  color: var(--main-text-color);

  user-select: none;
  /* TODO:  */
  background-color: var(--main-bg-color-main);
}

.App {
  width: 100%;
  min-height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  text-align: center;
}

.App-inner-wrapper {
  width: 100%;
  /* min-height: 100vh; */
  height: 100vh;
  /* padding: 0 5rem; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* Small screens */
@media screen and (max-width: 480px) {
  .header {
    margin: 3rem 1rem 1rem;
  }

  .header h2 {
    font-size: 3rem;
  }
  .header .city {
    font-size: 3rem;
  }

  .contact-details {
    width: 800px;
  }
  .language-picker {
    width: calc(100% - 2rem);

    margin: 0 auto;
  }
  .dish {
    max-width: 800px;
    margin: 0 auto;
  }
}
