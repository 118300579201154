.footer {
  width: 100%;

  /*   
  position: fixed;
  bottom: 0;
  left: 0; 
  */

  padding: 1rem 0 1rem;

  /* align-self: flex-end; */

  display: flex;
  flex-direction: column;
  gap: 1rem;

  border-top: 2px solid #b7612c;
  background-color: var(--main-bg-color-darker-grey);
  background-color: var(--main-bg-color-darker-grey-2);
}

.footer-line {
  margin: 0 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
